import letterIllustration from 'assets/images/illu-letter.svg'
import { ViewHeader } from 'components/ViewHeader'
import useCallerActionsApi, { TCallerActionsPrintFormRequestParams } from 'hooks/useCallerActionsApi'
import BackButton from 'partials/BackButton'
import { FunctionComponent, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import routesDictionary from 'routes'
import Button, { ButtonType } from 'shared/components/Button'
import Form, { FormFields, FormFieldType, FormRefActions } from 'shared/components/Form'
import { TextInputType } from 'shared/components/TextInput'
import useGlobalModalState from 'shared/hooks/useGlobalModalState'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { useBackButtonPath } from 'state/useNavigationState'
import { useCallerActionsContext } from './CallerActions.context'
import PdfViewer from 'shared/components/PdfViewer'

const CallerActionsSendInitialLetter: FunctionComponent = () => {
	const { t } = useTranslation()
	const [, modalActions] = useGlobalModalState()
	const { getChildPath, navigateTo } = useRouteHelper(routesDictionary.callerActions)
	useBackButtonPath(getChildPath([routesDictionary.callerActions], 'dashboard'))
	const { userDetails, userAuthData } = useCallerActionsContext()
	const { postPrintedForm } = useCallerActionsApi()
	const formRef = useRef<FormRefActions>()

	const tenant = userAuthData?.tenant.toLowerCase()
	const selectedPDFForm = `/assets/tenant/${tenant}/initial-letter-de.pdf`

	const handleOnSuccess = () => {
		modalActions.setClass('modal--success')
		modalActions.setContent(
			<>
				<img src={letterIllustration} alt="" />
				<h2>
					<Trans i18nKey={`view.callerActionsSendInitialLetter.success.headline`} />
				</h2>
				<p>
					<Trans i18nKey={`view.callerActionsSendInitialLetter.success.content`} />
				</p>
				<Button type={ButtonType.primary} label={t('generic.done')} onClick={() => modalActions.closeModal()} />
			</>
		)

		modalActions.setHideCloseButtons()
		modalActions.setOnCloseAction(() => navigateTo(getChildPath([routesDictionary.callerActions], 'dashboard')))
		modalActions.openModal()
	}
	const handleOnSubmit = async (submittedFields: Pick<TCallerActionsPrintFormRequestParams, 'formType'>) => {
		if (!userAuthData) {
			return false
		}

		return await postPrintedForm({
			...submittedFields,
			...userAuthData,
		})
	}

	const inputFields: FormFields = {
		formType: {
			type: TextInputType.hidden,
			fieldType: FormFieldType.text,
			value: 'INITIAL',
		},
	}

	return (
		<div className="caller-actions">
			<ViewHeader
				headline={t('view.callerActionsSendInitialLetter.headline')}
				subheadline={t('view.callerActionsSendInitialLetter.subtitle')}
				button={<BackButton />}
			/>
			<div className="flex margin--top">
				<div>
					<b className="margin--bottom">
						<Trans i18nKey={'generic.exampleLetter'} />
					</b>
					<PdfViewer className="margin--top" document={selectedPDFForm} />
				</div>
				<div className="caller-actions__grid max-content-width--narrow">
					<div className="caller-actions__grid-item--full">
						<div className="sticky">
							<p className="font-family-bold margin--top">
								<Trans i18nKey="view.callerActionsSendInitialLetter.copytext" />
							</p>
							<p className="font-family-bold caller-actions__grid-item--full text-color-teal margin--vertical">
								{userDetails?.title} {userDetails?.firstName} {userDetails?.namePrefix}{' '}
								{userDetails?.lastName} {userDetails?.nameAffix}
								<br />
								{userDetails?.sendAddress?.street} {userDetails?.sendAddress?.houseNumber}
								<br />
								{userDetails?.sendAddress?.zip} {userDetails?.sendAddress?.city}
							</p>
							<Trans i18nKey="view.callerActionsSendInitialLetter.disclaimer" />
							<Form
								ref={formRef}
								fields={inputFields}
								onSubmit={handleOnSubmit}
								onSuccess={handleOnSuccess}
								alwaysAllowSubmit={true}
								errorMessages={[t('view.callerActionsSendInitialLetter.form.error')]}
								hideSubmit={true}
							/>{' '}
							<Button
								className="margin--top margin--large"
								label={t('view.callerActionsSendInitialLetter.form.submit')}
								type={ButtonType.highlight}
								onClick={() => formRef.current?.submitForm()}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default CallerActionsSendInitialLetter
